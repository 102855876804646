<template>
  <a-modal
    :title="title + '文件夹'"
    :width="900"
    :visible="visible"
    :confirmLoading="confirmLoading"
    okText="确认"
    cancelText="取消"
    @ok="handleSubmit"
    @cancel="handleCancel"
  >
    <a-spin :spinning="confirmLoading">
      <a-form :form="form">
        <a-form-item
          label="目标文件夹"
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
          has-feedback=""
        >
          <a-tree-select
            v-decorator="['parentId', {rules: [{required: true, message: '请选择目标文件夹'}]}]"
            style="width: 100%"
            :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
            :tree-data="treeData"
            :replaceFields="{children:'children', title:'name', key:'key', value: 'key' }"
            placeholder="请选择目标文件夹"
            tree-default-expand-all
          >
          </a-tree-select>
        </a-form-item>
      </a-form>
    </a-spin>
  </a-modal>
</template>

<script>
import { listDirTree, copyOrCut } from '@/api/system/source'
import pick from 'lodash.pick'

// 表单字段
const fields = [
  'parentId'
]

export default {
  name: 'SourceFileCopyOrCutModal',
  data () {
    return {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 15 }
      },
      visible: false,
      confirmLoading: false,
      form: this.$form.createForm(this),
      treeData: [],
      id: null,
      copyOrCut: null,
      title: null
    }
  },
  methods: {

    init(item, isCopy) {
      this.title = isCopy ? '复制' : '移动'
      this.handleDirTree()
      this.id = item.id
      this.copyOrCut = isCopy ? 1 : 2
      this.visible = true
      this.$nextTick(()=> {
        this.form.setFieldsValue(pick(item, fields))
      })
    },

    handleSubmit () {
      const { form: { validateFields } } = this
      this.confirmLoading = true
      validateFields((errors, values) => {
        if (!errors) {
          copyOrCut(Object.assign(values, { id: this.id, copyOrCut: this.copyOrCut })).then((res) => {
            this.confirmLoading = false
            if (res.code === 200) {
              this.$notification.success({message: this.title + '成功'})
              this.handleCancel()
              this.$emit('ok', values)
            }
          }).finally((res) => {
            this.confirmLoading = false
          })
        } else {
          this.confirmLoading = false
        }
      })
    },

    handleCancel () {
      this.form.resetFields()
      this.visible = false
      this.id = null
      this.copyOrCut = null
      this.title = null
    },

    handleDirTree () {
      this.loading = true
      listDirTree(0).then((res) => {
        if (res['code'] === 200) {
          if (this.modalType) {
            this.deleteThisDept(res.rows)
          }
          this.treeData = [{ name: '根目录', key: 0, children: null }]
          this.treeData[0].children = res.rows
        }
      }).finally(() => {
        this.loading = false
      })
    }
  }
}
</script>
